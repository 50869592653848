<template>
  <table class="table table-striped table-amz table-mandants" style="max-width: 1000px;">
    <tbody>
      <!-- Mandants -->
      <tr>
        <td class="font-weight-bold">
          Mandants
        </td>
        <td>
          <button
            :disabled="loadingCount > 0"
            type="button"
            class="btn btn-primary btn-sm"
            @click="removeMandants"
          >None</button>
        </td>
        <td>
          <button
            :disabled="loadingCount > 0"
            type="button"
            class="btn btn-primary btn-sm"
            @click="addMandants"
          >All</button>
        </td>
      </tr>
      <tr v-for="mandant in mandants" :key="mandant.code">
        <td class="text-right">{{ mandant.title }} - {{ mandant.code }}</td>
        <td>
          <label class="w-100">
            <b-form-radio
              class="radio-amz"
              :disabled="mandant.loading"
              :checked="mandantChecked(mandant)"
              :name="mandant.keyword"
              :value="false"
              @change="removeMandant(mandant)"
            ></b-form-radio>
          </label>
        </td>
        <td>
          <label class="w-100">
            <b-form-radio
              class="radio-amz"
              :disabled="mandant.loading"
              :checked="mandantChecked(mandant)"
              :name="mandant.keyword"
              :value="true"
              @change="addMandant(mandant)"
            ></b-form-radio>
          </label>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'PermissionsSelect',
  components: {},
  props: ['mandants', 'account', 'accountMandants'],
  computed: {
    HasAccountMandants() {
      return Object.keys(this.accountMandants).length > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
    };
  },
  methods: {
    mandantChecked(mandant) {
      return this.accountMandants.indexOf(mandant.code) > -1;
    },
    categoryDisabled(mandantCategory) {
      return mandantCategory.mandants.some((mandant) => mandant.loading);
    },
    addMandant(mandant) {
      if (mandant.loading) return;
      mandant.loading = true;
      this.$emit('loadingStarted');
      this.$emit('mandantAdded', mandant);
      this.$http
        .post(`/account_mandants/${this.account.id}`)
        .send({ mandantCode: mandant.code })
        .then(() => {
          // nothing to handle
        })
        .catch((err) => {
          alert(`Failed to add mandant: ${err.response.text}`);
          this.$emit('mandantRemoved', mandant);
        })
        .finally(() => {
          mandant.loading = false;
          this.$emit('loadingEnded');
        });
    },
    removeMandant(mandant) {
      if (mandant.loading) return;
      mandant.loading = true;
      this.$emit('loadingStarted');
      this.$emit('mandantRemoved', mandant);
      this.$http
        .delete(`/account_mandants/${this.account.id}`)
        .send({ mandantCode: mandant.code })
        .then(() => {
          // nothing to handle
        })
        .catch((err) => {
          alert(`Failed to remove mandant: ${err.response.text}`);
          this.$emit('mandantAdded', mandant);
        })
        .finally(() => {
          mandant.loading = false;
          this.$emit('loadingEnded');
        });
    },
    addMandants() {
      this.mandants.forEach((mandant) => {
        if (!this.mandantChecked(mandant)) this.addMandant(mandant);
      });
    },
    removeMandants() {
      this.mandants.forEach((mandant) => {
        if (this.mandantChecked(mandant)) this.removeMandant(mandant);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-mandants tbody>tr>td:nth-child(1) {
  font-weight: 600;
}
</style>
